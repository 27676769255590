export const ACCOUNTS = 'ACCOUNTS';
export const LOGIN = 'LOGIN';
export const NAVIGATION = 'NAVIGATION';
export const SEARCH = 'SEARCH';
export const SHORTCUTS = 'SHORTCUTS';
export const SUMMARY_REPORT_EXPORT = 'SUMMARY_REPORT_EXPORT';
export const TASKS = 'TASKS';

export const DIALOGS = {
  ACCOUNTS,
  LOGIN,
  NAVIGATION,
  SEARCH,
  SHORTCUTS,
  SUMMARY_REPORT_EXPORT,
  TASKS,
} as const;
