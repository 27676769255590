import { CURRENCIES, type Currency } from '@/constants/currencies';

export function camelToSnake(str: string) {
  return str
    .replace(/ID/g, 'Id')
    .split('')
    .map((v, i) => {
      const isNumber = (char: any) => !isNaN(parseInt(char));
      if (i === 0) return v.toLowerCase();
      if (isNumber(v) && !isNumber(str[i - 1])) return `_${v}`;
      if (v !== v.toLowerCase()) return `_${v.toLowerCase()}`;
      return v.toLowerCase();
    })
    .join('');
}

export function renderPrice(
  price = 0,
  currency: Currency | string | null = 'EUR',
  options: { language?: string; useRounding?: boolean } = {
    language: navigator.language,
  }
) {
  if (!currency || currency === '') {
    return formatNumber(price);
  }
  let formatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency,
  };
  if (options.useRounding) formatOptions.maximumFractionDigits = 0;
  return new Intl.NumberFormat(options.language, formatOptions).format(price);
}

export function isString(val: any): val is string {
  return typeof val === 'string';
}

export function formatNumber(val: number, fractionDigits = 2) {
  return new Intl.NumberFormat('default', {
    maximumFractionDigits: fractionDigits,
  }).format(val);
}

export function formatPercentage(val: number, fractionDigits = 2) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(val);
}

export function renderUserName(
  user?: { firstname: string; lastname?: string },
  includeLastName = true,
  useLastInitial = false
) {
  if (!user) {
    return '';
  } else if (!user.lastname || !includeLastName) {
    return `${user.firstname}`;
  } else if (useLastInitial) {
    return `${user.firstname} ${user.lastname.charAt(0)}.`;
  } else {
    return `${user.firstname} ${user.lastname}`;
  }
}

export function padNumber(num: number) {
  return `${num}`.length === 1 ? `0${num}` : `${num}`;
}

export function isBlank(
  str: string | null | undefined
): str is null | undefined {
  return !str || str.trim().length === 0;
}

export function compareStrings(
  str1: string,
  str2: string,
  caseSensitive = false
) {
  const sensitivity = caseSensitive ? 'base' : 'variant';
  return str1.localeCompare(str2, navigator.languages as string[], {
    sensitivity,
  });
}

export function renderCurrency(currencyValue: keyof typeof CURRENCIES) {
  return CURRENCIES[currencyValue]?.symbol || '';
}
