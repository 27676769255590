import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UnreadNotificationsCountState = number | null;

const initialState =
  null satisfies UnreadNotificationsCountState as UnreadNotificationsCountState;

export const unreadNotificationsCountSlice = createSlice({
  name: 'unreadNotificationsCount',
  initialState,
  reducers: {
    setUnreadNotificationsCount: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setUnreadNotificationsCount } =
  unreadNotificationsCountSlice.actions;

export default unreadNotificationsCountSlice.reducer;
