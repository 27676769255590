'use client';
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type Notification, UnauthorizedError } from '@witmetrics/api-client';
import { showErrorToaster } from '@/utils/toasters';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import { setActiveAccount as dispatchSetActiveAccount } from '@/store/slices/activeAccountSlice';
import { DIALOGS } from '@/constants/dialogs';
import { useSidebarCollapsed } from '@/hooks/useSidebarCollapsed';
import { usePath } from '@/hooks/usePath';
import { type Dictionary } from '@/types';
import { useSockets } from '@/providers/SocketsProvider';
import { getCRMURL, getUnisonURL } from '@/api/getURL';
import {
  DEFAULT_DIALOGS,
  isLeadNotification,
  isProjectNotification,
  isSequenceNotification,
  isSequenceStepNotification,
  updateActiveAccount,
  updateReadNotification,
  useStoredAccountID,
} from './utils';

type AppStateValue = {
  isSidebarCollapsed: boolean | undefined;
  activeDialogs: Dictionary<boolean>;
  onToggleSidebarCollapsed: (isCollapsed?: boolean) => void;
  setActiveDialogs: Dispatch<SetStateAction<{ [p: string]: boolean }>>;
  onLogin: () => void;
  onApiError: (err: any, message: string, cb?: Function) => void;
  onOpenNotification: (notification: Notification) => void;
  onOpenDialog: (dialogName: string) => void;
  onCloseDialog: (dialogName: string) => void;
};

const AppStateContext = createContext<AppStateValue>({
  isSidebarCollapsed: false,
  activeDialogs: DEFAULT_DIALOGS,
  onToggleSidebarCollapsed: () => null,
  setActiveDialogs: () => null,
  onLogin: () => null,
  onApiError: () => null,
  onOpenNotification: () => null,
  onOpenDialog: () => null,
  onCloseDialog: () => null,
});

export default function AppStateProvider({ children }: PropsWithChildren) {
  const loginCallbacks = useRef<Function[]>([]);
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { setApiErrorCallback } = useSockets();
  const { navigate, pushParams, removeParams } = usePath();
  const [activeDialogs, setActiveDialogs] = useState(DEFAULT_DIALOGS);
  const [isSidebarCollapsed, toggleSidebarCollapsed] = useSidebarCollapsed();
  const [storedAccountID, setStoredAccountID] = useStoredAccountID();

  useEffect(() => {
    setApiErrorCallback(handleSocketError);
  }, []);

  useEffect(() => {
    if (!activeAccount) return;
    if (activeAccount.accountID !== storedAccountID) {
      handleResetAccountItems();
    }
  }, [activeAccount]);

  const handleResetAccountItems = () => {
    setStoredAccountID(activeAccount!.accountID);
  };

  const handleOpenDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: true }));
  };

  const handleCloseDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: false }));
  };

  const handleSocketError = () => {
    handleApiError(new UnauthorizedError('', ''), '');
  };

  const handleApiError = (err: any, toasterMessage: string, cb?: Function) => {
    if (err instanceof UnauthorizedError) {
      if (cb) loginCallbacks.current.push(cb);
      if (!activeDialogs[DIALOGS.LOGIN]) handleOpenDialog('login');
    } else {
      showErrorToaster(toasterMessage);
      throw err;
    }
  };

  const handleLogin = () => {
    if (loginCallbacks.current) {
      loginCallbacks.current.forEach((cb) => cb());
    }
    handleCloseDialog(DIALOGS.LOGIN);
    loginCallbacks.current = [];
  };

  const handleToggleSidebar = (isCollapsed?: boolean) => {
    if (isCollapsed === undefined) {
      toggleSidebarCollapsed(!isSidebarCollapsed);
    } else {
      toggleSidebarCollapsed(isCollapsed);
    }
  };

  const handleOpenNotification = async (notification: Notification) => {
    // Make sure the correct account is active
    if (notification.practiceID !== activeAccount?.accountID) {
      const account = await updateActiveAccount(notification.practiceID);
      dispatch(dispatchSetActiveAccount(account));
    }

    // No need to wait on this, the socket event will handle it
    if (!notification.isRead) updateReadNotification(notification.id);

    // Determine what to open / navigate to
    if (isProjectNotification(notification)) {
      return navigate(getUnisonURL(), {
        activeProjectID: notification.content.unisonProjectID,
      });
    } else if (isSequenceNotification(notification)) {
      // TODO
      return showErrorToaster('Sequence notifications not yet implemented');
    } else if (isSequenceStepNotification(notification)) {
      // TODO
      return showErrorToaster('Sequence notifications not yet implemented');
    } else if (isLeadNotification(notification)) {
      return navigate(getCRMURL(), {
        activeLeadID: notification.content.leadID,
      });
    } else {
      showErrorToaster('Error opening notification');
      throw Error(
        // @ts-ignore
        `Unrecognized notificationTypeID: ${notification.notificationTypeID}`
      );
    }
  };

  const contextValue = {
    isSidebarCollapsed,
    activeDialogs,
    onToggleSidebarCollapsed: handleToggleSidebar,
    setActiveDialogs,
    onLogin: handleLogin,
    onApiError: handleApiError,
    onOpenNotification: handleOpenNotification,
    onOpenDialog: handleOpenDialog,
    onCloseDialog: handleCloseDialog,
  };

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
