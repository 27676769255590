import type { AppDispatch } from '@/store/store';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import type { IsReadFilter, Notification } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  deleteAllNotifications,
  deleteNotification,
  setNotifications,
  updateAllNotifications,
  updateNotification,
} from '@/store/slices/notificationsSlice';
import { setUnreadNotificationsCount } from '@/store/slices/unreadNotificationsCountSlice';

const {
  NEW_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_ALL_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
} = SOCKET_EVENTS;

export function subscribeNotifications(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: NEW_NOTIFICATION,
    callback: (data: { unreadCount: number; notification: Notification }) => {
      dispatch(setNotifications([data.notification]));
      dispatch(setUnreadNotificationsCount(data.unreadCount));
    },
  });
  subscribe({
    endpoint: UPDATE_NOTIFICATION,
    callback: (data: {
      unreadCount: number;
      notification: { notificationID: number; isRead: boolean };
    }) => {
      dispatch(updateNotification(data.notification));
      dispatch(setUnreadNotificationsCount(data.unreadCount));
    },
  });
  subscribe({
    endpoint: UPDATE_ALL_NOTIFICATIONS,
    callback: (data: { unreadCount: number; isRead: boolean }) => {
      dispatch(updateAllNotifications({ isRead: data.isRead }));
      dispatch(setUnreadNotificationsCount(data.unreadCount));
    },
  });
  subscribe({
    endpoint: DELETE_NOTIFICATION,
    callback: (data: { unreadCount: number; notificationID: number }) => {
      dispatch(deleteNotification({ notificationID: data.notificationID }));
      dispatch(setUnreadNotificationsCount(data.unreadCount));
    },
  });
  subscribe({
    endpoint: DELETE_ALL_NOTIFICATIONS,
    callback: (data: { unreadCount: number; filter?: IsReadFilter }) => {
      dispatch(deleteAllNotifications({ filter: data.filter }));
      dispatch(setUnreadNotificationsCount(data.unreadCount));
    },
  });
}
