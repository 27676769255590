import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from 'react-redux';
import type { AppDispatch, RootState } from './store';
import {
  buildActiveAccount,
  buildAllNotifications,
  buildCurrencyPreference,
  buildCurrentUser,
  buildDateFormatPreference,
  buildLogoPreference,
  buildNotification,
  buildNotificationsFromIDArray,
  buildPracticePreferences,
  buildPracticeProperties,
  buildPropertiesFromIDArray,
  buildProperty,
  buildUnreadNotificationsCount,
  buildWeekStartsOnPreference,
} from './utils/buildState';
import isEqual from 'lodash/isEqual';

export const useDispatch: () => AppDispatch = _useDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;

function useBuilder(
  buildMethod: (state: RootState, identifier?: any) => any,
  identifier?: any
) {
  if (identifier !== undefined) {
    return useSelector((state) => buildMethod(state, identifier), isEqual);
  } else {
    return useSelector((state) => buildMethod(state), isEqual);
  }
}

export function useActiveAccount(): ReturnType<typeof buildActiveAccount> {
  return useBuilder(buildActiveAccount);
}

export function useCurrentUser(): ReturnType<typeof buildCurrentUser> {
  return useBuilder(buildCurrentUser);
}

export function useDateFormatPreference(): ReturnType<
  typeof buildDateFormatPreference
> {
  return useBuilder(buildDateFormatPreference);
}

export function useWeekStartsOnPreference(): ReturnType<
  typeof buildWeekStartsOnPreference
> {
  return useBuilder(buildWeekStartsOnPreference);
}

export function usePracticeProperties(): ReturnType<
  typeof buildPracticeProperties
> {
  return useBuilder(buildPracticeProperties);
}

export function usePracticePreferences(
  practiceID?: number
): ReturnType<typeof buildPracticePreferences> {
  return useBuilder(buildPracticePreferences, practiceID);
}

export function usePracticeCurrency(
  practiceID?: number
): ReturnType<typeof buildCurrencyPreference> {
  return useBuilder(buildCurrencyPreference, practiceID);
}

export function usePracticeLogo(
  practiceID?: number
): ReturnType<typeof buildLogoPreference> {
  return useBuilder(buildLogoPreference, practiceID);
}

export function useProperty(
  propertyID: number
): ReturnType<typeof buildProperty> {
  return useBuilder(buildProperty, propertyID);
}

export function usePropertiesFromIDArray(
  idArray: number[]
): ReturnType<typeof buildPropertiesFromIDArray> {
  return useBuilder(buildPropertiesFromIDArray, idArray);
}

export function useNotification(
  notificationID: number
): ReturnType<typeof buildNotification> {
  return useBuilder(buildNotification, notificationID);
}

export function useAllNotifications(): ReturnType<
  typeof buildAllNotifications
> {
  return useBuilder(buildAllNotifications);
}

export function useNotificationsFromIDArray(
  idArray: number[]
): ReturnType<typeof buildNotificationsFromIDArray> {
  return useBuilder(buildNotificationsFromIDArray, idArray);
}

export function useUnreadNotificationsCount(): ReturnType<
  typeof buildUnreadNotificationsCount
> {
  return useBuilder(buildUnreadNotificationsCount);
}
