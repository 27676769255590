import {
  type NewBookingNotification,
  type NewLeadTaskNotification,
  type NewUnisonProjectTaskNotification,
  type NewUnisonProjectNoteMentionNotification,
  type NewUnisonProjectNoteCommentMentionNotification,
    type NewSequenceNoteMentionNotification,
    type NewSequenceNoteCommentMentionNotification,
  type NewSequenceStepNoteMentionNotification,
    type NewSequenceStepNoteCommentMentionNotification,
  type NewInteractionMentionNotification,
  type NewInteractionCommentMentionNotification,
  type Notification,
  NOTIFICATION_TYPE_ID,
} from '@witmetrics/api-client';

const {
  NEW_BOOKING,
    NEW_LEAD_TASK,
  NEW_UNISON_PROJECT_TASK,
    NEW_UNISON_PROJECT_NOTE_MENTION,
    NEW_UNISON_PROJECT_NOTE_COMMENT_MENTION,
    NEW_SEQUENCE_NOTE_MENTION,
    NEW_SEQUENCE_NOTE_COMMENT_MENTION,
    NEW_SEQUENCE_STEP_NOTE_MENTION,
    NEW_SEQUENCE_STEP_NOTE_COMMENT_MENTION,
    NEW_INTERACTION_MENTION,
    NEW_INTERACTION_COMMENT_MENTION,
} = NOTIFICATION_TYPE_ID;

export function isNewBookingNotification(
    notification: Notification
): notification is NewBookingNotification {
  return notification.notificationTypeID === NEW_BOOKING;
}

export function isNewLeadTaskNotification(
    notification: Notification
): notification is NewLeadTaskNotification {
  return notification.notificationTypeID === NEW_LEAD_TASK;
}

export function isNewUnisonProjectTask(
  notification: Notification
): notification is NewUnisonProjectTaskNotification {
  return notification.notificationTypeID === NEW_UNISON_PROJECT_TASK;
}

export function isNewUnisonProjectNoteMention(
  notification: Notification
): notification is NewUnisonProjectNoteMentionNotification {
  return notification.notificationTypeID === NEW_UNISON_PROJECT_NOTE_MENTION;
}

export function isNewUnisonProjectNoteCommentMention(
  notification: Notification
): notification is NewUnisonProjectNoteCommentMentionNotification {
  return notification.notificationTypeID === NEW_UNISON_PROJECT_NOTE_COMMENT_MENTION;
}

export function isNewSequenceNoteMention(
  notification: Notification
): notification is NewSequenceNoteMentionNotification {
  return notification.notificationTypeID === NEW_SEQUENCE_NOTE_MENTION;
}

export function isNewSequenceNoteCommentMention(
  notification: Notification
): notification is NewSequenceNoteCommentMentionNotification {
  return notification.notificationTypeID === NEW_SEQUENCE_NOTE_COMMENT_MENTION;
}

export function isNewSequenceStepNoteMention(
  notification: Notification
): notification is NewSequenceStepNoteMentionNotification {
  return notification.notificationTypeID === NEW_SEQUENCE_STEP_NOTE_MENTION;
}

export function isNewSequenceStepNoteCommentMention(
  notification: Notification
): notification is NewSequenceStepNoteCommentMentionNotification {
  return notification.notificationTypeID === NEW_SEQUENCE_STEP_NOTE_COMMENT_MENTION;
}

export function isNewInteractionMention(
  notification: Notification
): notification is NewInteractionMentionNotification {
  return notification.notificationTypeID === NEW_INTERACTION_MENTION;
}

export function isNewInteractionCommentMention(
  notification: Notification
): notification is NewInteractionCommentMentionNotification {
  return notification.notificationTypeID === NEW_INTERACTION_COMMENT_MENTION;
}
