import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IsReadFilter, Notification } from '@witmetrics/api-client';

type NotificationsState = {
  byID: Record<string, Notification>;
};

const initialState = {
  byID: {},
} satisfies NotificationsState as NotificationsState;

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      return getNextState(state, action.payload);
    },
    updateNotification: (
      state,
      action: PayloadAction<{ notificationID: number; isRead: boolean }>
    ) => {
      const key = `${action.payload.notificationID}`;
      if (state.byID[key]) state.byID[key].isRead = action.payload.isRead;
    },
    updateAllNotifications: (
      state,
      action: PayloadAction<{ isRead: boolean }>
    ) => {
      Object.keys(state.byID).forEach((key) => {
        state.byID[key].isRead = action.payload.isRead;
      });
    },
    deleteNotification: (
      state,
      action: PayloadAction<{ notificationID: number }>
    ) => {
      delete state.byID[`${action.payload.notificationID}`];
    },
    deleteAllNotifications: (
      state,
      action: PayloadAction<{ filter?: IsReadFilter }>
    ) => {
      if (action.payload.filter) {
        Object.keys(state.byID).forEach((key) => {
          if (state.byID[key].isRead === action.payload.filter!.value) {
            delete state.byID[key];
          }
        });
      } else {
        state.byID = {};
      }
    },
  },
});

function getNextState(
  state: NotificationsState,
  notifications: Notification[]
) {
  let byID = { ...state.byID };
  notifications.forEach((notification) => {
    const key = `${notification.id}`;
    byID[key] = notification;
  });
  return { byID };
}

export const {
  setNotifications,
  updateNotification,
  updateAllNotifications,
  deleteNotification,
  deleteAllNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
