export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const ALL = 'ALL';

export const TIME_PERIODS = {
  DAY,
  WEEK,
  MONTH,
  YEAR,
  ALL,
} as const;

export type TimePeriod = keyof typeof TIME_PERIODS;
