import { io } from 'socket.io-client';
import { getAPIURL } from '@/api/getURL';
import { type AppDispatch } from '@/store/store';
import { isEmpty } from '@/utils/arrays';
import { UnauthorizedError } from '@witmetrics/api-client';
import { subscribeNotifications } from './subscriptions/notifications';
import type {
  EventError,
  JoinManager,
  JoinQueueItem,
  JoinSettings,
  SocketSubscription,
  Subscribe,
  SubscriptionManager,
  SubscriptionQueueItem,
} from '@/types/sockets';

export const ACTIVE_WARNING = 'No active socket connection';

export function connectSocket(onApiError: (err: any) => void) {
  return Socket(getAPIURL(), onApiError);
}

export function initiateDispatchSockets(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribeNotifications(dispatch, subscribe);
}

function Socket(url: string, onApiError: (err: any) => void) {
  const socket = io(url, { withCredentials: true });
  let isSubscriptionQueueLocked = false;
  let isJoinQueueLocked = false;
  let subscriptionQueue: SubscriptionQueueItem[] = [];
  let subscriptionManager: SubscriptionManager = {};
  let joinQueue: JoinQueueItem[] = [];
  let joinManager: JoinManager = {};

  socket.on('connect', () => {
    // Reconnect any subscriptions that were broken on disconnect
    if (!isEmpty(subscriptionManager)) placeSubscriptionManagerInQueue();
    if (!isEmpty(joinManager)) placeJoinManagerInQueue();
  });

  socket.on('connect_error', ({ message }) => {
    if (message === 'Unauthorized') {
      onApiError(new UnauthorizedError(message, message));
    } else {
      console.warn(message);
    }
  });

  function placeSubscriptionManagerInQueue() {
    // Move each subscription from the manager into the queue
    addToSubscriptionQueue(
      Object.keys(subscriptionManager).map((key) => ({
        endpoint: key,
        callback: subscriptionManager[key].callback,
        data: subscriptionManager[key].data,
      }))
    );
    subscriptionManager = {};
  }

  function placeJoinManagerInQueue() {
    // Move each join from the manager into the queue
    addToJoinQueue(
      Object.keys(joinManager).map((key) => ({
        endpoint: key,
        callback: joinManager[key].callback,
        data: joinManager[key].data,
      }))
    );
    joinManager = {};
  }

  function addToSubscriptionQueue(subscriptions: SocketSubscription[]) {
    subscriptionQueue.push(...subscriptions);
    if (!isSubscriptionQueueLocked) runSubscriptionQueue();
  }

  function addToJoinQueue(joins: JoinSettings[]) {
    joinQueue.push(...joins);
    if (!isJoinQueueLocked) runJoinQueue();
  }

  function runSubscriptionQueue() {
    if (isEmpty(subscriptionQueue)) {
      isSubscriptionQueueLocked = false;
      return;
    }
    isSubscriptionQueueLocked = true;
    let queueItem = subscriptionQueue.shift();
    if (queueItem) {
      processSubscriptionQueueItem(queueItem)
        .then(runSubscriptionQueue)
        .catch((err: any) => {
          throw err;
        });
    }
  }

  function runJoinQueue() {
    if (isEmpty(joinQueue)) {
      isJoinQueueLocked = false;
      return;
    }
    isJoinQueueLocked = true;
    let queueItem = joinQueue.shift();
    if (queueItem) {
      processJoinQueueItem(queueItem)
        .then(runJoinQueue)
        .catch((err: any) => {
          throw err;
        });
    }
  }

  function processSubscriptionQueueItem(queueItem: SubscriptionQueueItem) {
    const { endpoint, callback, data } = queueItem;
    return new Promise((resolve) => {
      socket.on(
        'subscribe',
        (endpoint: string, data: any, error?: EventError) => {
          socket.off('subscribe');
          if (error && error.type.length > 0) {
            warnJoinError(endpoint, error);
            resolve(endpoint); // Resolve anyways so queue can continue
          } else {
            subscriptionManager[endpoint] = { callback, data };
            socket.off(endpoint);
            socket.on(endpoint, (update) => {
              if (subscriptionManager[endpoint]?.callback instanceof Function) {
                try {
                  subscriptionManager[endpoint].callback(update);
                } catch (err) {
                  warnRunError(endpoint, subscriptionManager);
                }
              } else {
                warnMissingError(endpoint, subscriptionManager);
              }
            });
            resolve(endpoint);
          }
        }
      );
      socket.emit('subscribe', endpoint, data);
    });
  }

  function processJoinQueueItem(queueItem: JoinQueueItem) {
    const { endpoint, data, callback } = queueItem;
    return new Promise((resolve) => {
      socket.on('join', (endpoint: string, data: any, error?: EventError) => {
        socket.off('join');
        if (error && error.type.length > 0) {
          warnJoinError(endpoint, error);
          if (callback) callback(data, error);
          resolve(endpoint); // Resolve anyways so queue can continue
        } else {
          joinManager[endpoint] = { callback, data };
          socket.off(endpoint);
          if (callback) {
            try {
              callback(data, error);
            } catch (err) {
              warnRunError(endpoint, joinManager);
            }
          }
          resolve(endpoint);
        }
      });
      socket.emit('join', endpoint, data);
    });
  }

  function subscribe(subscription: SocketSubscription | SocketSubscription[]) {
    if (Array.isArray(subscription)) {
      // Bulk add the new subscriptions (and clear out stale versions)
      subscription.forEach((s) => checkExistingSubscription(s.endpoint));
      addToSubscriptionQueue(subscription);
    } else {
      checkExistingSubscription(subscription.endpoint);
      addToSubscriptionQueue([subscription]);
    }
  }

  function checkExistingSubscription(endpoint: string) {
    if (subscriptionManager[endpoint]) {
      warnSubscribeError(endpoint);
      socket.off(endpoint);
    }
  }

  function unsubscribe(endpoint: string) {
    socket.off(endpoint);
    socket.emit('unsubscribe', endpoint);
    delete subscriptionManager[endpoint];
  }

  function join(joinSettings: JoinSettings | JoinSettings[]) {
    if (Array.isArray(joinSettings)) addToJoinQueue(joinSettings);
    else addToJoinQueue([joinSettings]);
  }

  function leave(endpoint: string) {
    socket.emit('leave', { endpoint });
    delete joinManager[endpoint];
  }

  function send(endpoint: string, data: any) {
    return socket.emit(endpoint, data);
  }

  return { subscribe, unsubscribe, join, leave, send, socket };
}

function warnJoinError(
  endpoint: string,
  error: { type: string; message: string }
) {
  return console.warn(
    `${error.type}: Error joining socket "${endpoint}". ${error.message}`
  );
}

function warnRunError(
  endpoint: string,
  manager: SubscriptionManager | JoinManager
) {
  return console.warn(
    `Unable to run socket endpoint "${endpoint}". It was either not found in ${JSON.stringify(
      Object.keys(manager)
    )} or is not a valid function.`
  );
}

function warnMissingError(
  endpoint: string,
  manager: SubscriptionManager | JoinManager
) {
  return console.warn(
    `Socket endpoint "${endpoint}" was either not found in ${JSON.stringify(
      Object.keys(manager)
    )} or is not a valid function`
  );
}

function warnSubscribeError(endpoint: string) {
  return console.warn(
    `Existing subscription for "${endpoint}". Existing subscription will be overwritten.`
  );
}
