import { ACTIVE_ACCOUNTS } from '@/constants/activeAccounts';
import { RootState } from '@/store/store';
import { PREFERENCE_IDS } from '@/constants/preferences';
import type {
  DecoratedPracticePreference,
  DecoratedUserPractice,
  Notification,
  UndecoratedUserPreference,
} from '@witmetrics/api-client';
import { buildUserPreferenceKey } from '@/store/slices/userPreferencesSlice';
import { filterBlanks } from '@/utils/arrays';
import { ParsedProperty } from '@/types/properties';
import { Dictionary } from '@/types';
import { WeekStartsOn } from '@/types/dates';
import { DateFormat } from '@/constants/dateFormatPreference';

export type Identifier = number | string;

const { PRACTICE, ORGANIZATION } = ACTIVE_ACCOUNTS;

function getAccountID(state: RootState, practiceID?: Identifier) {
  let accountID = practiceID;
  if (!practiceID && state.activeAccount) {
    accountID = state.activeAccount.accountID;
  }
  return accountID;
}

function buildKey(identifier: Identifier | Identifier[]) {
  if (Array.isArray(identifier)) {
    return identifier.join('.');
  } else return `${identifier}`;
}

function buildFromIDArray<T, IDType>(
  state: RootState,
  sliceName: keyof Omit<
    RootState,
    'activeAccount' | 'currentUser' | 'unreadNotificationsCount'
  >,
  buildMethod: (state: RootState, id: IDType) => any,
  idArray: IDType[]
): T[] {
  if (!idArray) return [];
  return filterBlanks(
    idArray
      .filter((id) => Object.keys(state[sliceName].byID).includes(`${id}`))
      .map((id) => buildMethod(state, id))
  );
}

export function buildActiveAccount(state: RootState) {
  if (!state.activeAccount) return state.activeAccount;
  const accountType = state.activeAccount.accountType.toUpperCase();
  const key = buildKey(state.activeAccount.accountID);
  let account;
  if (accountType === PRACTICE) {
    account = state.practices.byID[key];
  } else if (accountType === ORGANIZATION) {
    throw Error('Organization accounts are not currently supported');
  } else {
    throw Error(`Unrecognized account type: "${accountType}"`);
  }
  return { ...state.activeAccount, account };
}

export function buildCurrentUser(state: RootState) {
  if (!state.currentUser) return state.currentUser;
  return {
    ...state.currentUser,
    userPractices: buildUserPractices(state, state.currentUser.id) || [],
    userPreferences: buildUserPreferences(state, state.currentUser.id),
  };
}

export function buildCurrentUserPreference(
  state: RootState,
  preferenceID: Identifier
) {
  if (!state.currentUser) return null;
  const key = buildUserPreferenceKey(state.currentUser.id, preferenceID);
  const userPreference = state.userPreferences.byID[key];
  if (!userPreference) return null;
  return userPreference.value;
}

export function buildDateFormatPreference(state: RootState) {
  return buildCurrentUserPreference(state, PREFERENCE_IDS.DATE_FORMAT) as
    | DateFormat
    | undefined;
}

export function buildWeekStartsOnPreference(state: RootState) {
  const preference = buildCurrentUserPreference(
    state,
    PREFERENCE_IDS.WEEK_STARTS_ON
  );
  if (!preference) return undefined;
  return parseInt(preference as string) as WeekStartsOn;
}

export function buildUserPractices(
  state: RootState,
  userID: Identifier
): DecoratedUserPractice[] {
  const key = buildKey(userID);
  const userPracticeIDs = state.userPractices.byUserID[key];
  if (!userPracticeIDs) return [];
  return buildFromIDArray<DecoratedUserPractice, string>(
    state,
    'userPractices',
    buildUserPractice,
    userPracticeIDs
  );
}

export function buildUserPractice(
  state: RootState,
  userPracticeID: Identifier
): DecoratedUserPractice | null {
  const key = buildKey(userPracticeID);
  const userPractice = state.userPractices.byID[key];
  if (!userPractice) return null;
  return {
    ...userPractice,
    practice: buildPractice(state, userPractice.practiceID),
  };
}

export function buildPractice(state: RootState, practiceID: Identifier) {
  return state.practices.byID[buildKey(practiceID)];
}

export function buildPropertiesFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<ParsedProperty, Identifier>(
    state,
    'properties',
    buildProperty,
    idArray
  );
}

export function buildPracticeProperties(
  state: RootState,
  practiceID: Identifier
): Dictionary<ParsedProperty> {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return {};
  const key = buildKey(accountID);
  const propertyIDs = state.properties.byPracticeID[key];
  if (!propertyIDs) return {};
  return propertyIDs
    .filter((pid) => Object.keys(state.properties.byID).includes(`${pid}`))
    .map((pid) => state.properties.byID[pid])
    .reduce((a, b) => ({ ...a, [b.name]: b }), {});
}

export function buildProperty(state: RootState, propertyID: Identifier) {
  const key = buildKey(propertyID);
  if (!state.properties.byID[key]) return null;
  return state.properties.byID[key] || null;
}

export function buildPracticePreferences(
  state: RootState,
  practiceID?: Identifier
): { [key: string]: DecoratedPracticePreference } | null {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey(accountID);
  const idArray = state.practicePreferences.byPracticeID[key];
  if (!idArray) return null;
  return buildFromIDArray<DecoratedPracticePreference, string>(
    state,
    'practicePreferences',
    buildPracticePreference,
    idArray
  )!.reduce((a, b) => ({ ...a, [b.preference.name]: b }), {});
}

export function buildPracticePreference(
  state: RootState,
  practicePreferenceID: Identifier
) {
  const key = buildKey(practicePreferenceID);
  const practicePreference = state.practicePreferences.byID[key];
  if (!practicePreference) return null;
  return {
    ...practicePreference,
    preference: buildPreference(state, practicePreference.preferenceID),
  };
}

export function buildLogoPreference(state: RootState, practiceID?: Identifier) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey([accountID, PREFERENCE_IDS.LOGO]);
  return buildPracticePreference(state, key);
}

export function buildCurrencyPreference(
  state: RootState,
  practiceID?: Identifier
) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey([accountID, PREFERENCE_IDS.CURRENCY]);
  return buildPracticePreference(state, key);
}

export function buildUserPreferences(
  state: RootState,
  userID: Identifier
): { [key: string]: UndecoratedUserPreference } | null {
  const key = buildKey(userID);
  const userPreferences = state.userPreferences.byUserID[key];
  if (!userPreferences) return null;
  return filterBlanks(
    userPreferences.map((id) => buildUserPreference(state, parseInt(id)))
  ).reduce((a, b) => ({ ...a, [b.preference.name]: b }), {});
}

export function buildUserPreference(
  state: RootState,
  userPreferenceID: Identifier
) {
  const key = buildKey(userPreferenceID);
  const userPreference = state.userPreferences.byID[key];
  if (!userPreference) return null;
  return {
    ...userPreference,
    preference: buildPreference(state, userPreference.preferenceID),
  };
}

export function buildPreference(state: RootState, preferenceID: Identifier) {
  const key = buildKey(preferenceID);
  const preference = state.preferences.byID[key];
  return preference || null;
}

export function buildNotification(
  state: RootState,
  notificationID: Identifier
) {
  const key = buildKey(notificationID);
  return state.notifications.byID[key] || null;
}

export function buildAllNotifications(state: RootState) {
  const idArray = Object.keys(state.notifications.byID);
  return buildFromIDArray<Notification, Identifier>(
    state,
    'notifications',
    buildNotification,
    idArray
  );
}

export function buildNotificationsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<Notification, Identifier>(
    state,
    'notifications',
    buildNotification,
    idArray
  );
}

export function buildUnreadNotificationsCount(state: RootState) {
  return state.unreadNotificationsCount;
}
