export const CURRENCIES = {
  USD: {
    symbol: '$',
    label: 'US Dollar',
    value: 'USD',
  },
  CAD: {
    symbol: 'CA$',
    label: 'Canadian Dollar',
    value: 'CAD',
  },
  EUR: {
    symbol: '€',
    label: 'Euro',
    value: 'EUR',
  },
  AED: {
    symbol: 'AED',
    label: 'United Arab Emirates Dirham',
    value: 'AED',
  },
  AFN: {
    symbol: 'Af',
    label: 'Afghan Afghani',
    value: 'AFN',
  },
  ALL: {
    symbol: 'ALL',
    label: 'Albanian Lek',
    value: 'ALL',
  },
  AMD: {
    symbol: 'AMD',
    label: 'Armenian Dram',
    value: 'AMD',
  },
  ARS: {
    symbol: 'AR$',
    label: 'Argentine Peso',
    value: 'ARS',
  },
  AUD: {
    symbol: 'AU$',
    label: 'Australian Dollar',
    value: 'AUD',
  },
  AZN: {
    symbol: 'man.',
    label: 'Azerbaijani Manat',
    value: 'AZN',
  },
  BAM: {
    symbol: 'KM',
    label: 'Bosnia-Herzegovina Convertible Mark',
    value: 'BAM',
  },
  BDT: {
    symbol: 'Tk',
    label: 'Bangladeshi Taka',
    value: 'BDT',
  },
  BGN: {
    symbol: 'BGN',
    label: 'Bulgarian Lev',
    value: 'BGN',
  },
  BHD: {
    symbol: 'BD',
    label: 'Bahraini Dinar',
    value: 'BHD',
  },
  BIF: {
    symbol: 'FBu',
    label: 'Burundian Franc',
    value: 'BIF',
  },
  BND: {
    symbol: 'BN$',
    label: 'Brunei Dollar',
    value: 'BND',
  },
  BOB: {
    symbol: 'Bs',
    label: 'Bolivian Boliviano',
    value: 'BOB',
  },
  BRL: {
    symbol: 'R$',
    label: 'Brazilian Real',
    value: 'BRL',
  },
  BWP: {
    symbol: 'BWP',
    label: 'Botswanan Pula',
    value: 'BWP',
  },
  BYN: {
    symbol: 'Br',
    label: 'Belarusian Ruble',
    value: 'BYN',
  },
  BZD: {
    symbol: 'BZ$',
    label: 'Belize Dollar',
    value: 'BZD',
  },
  CDF: {
    symbol: 'CDF',
    label: 'Congolese Franc',
    value: 'CDF',
  },
  CHF: {
    symbol: 'CHF',
    label: 'Swiss Franc',
    value: 'CHF',
  },
  CLP: {
    symbol: 'CL$',
    label: 'Chilean Peso',
    value: 'CLP',
  },
  CNY: {
    symbol: 'CN¥',
    label: 'Chinese Yuan',
    value: 'CNY',
  },
  COP: {
    symbol: 'CO$',
    label: 'Colombian Peso',
    value: 'COP',
  },
  CRC: {
    symbol: '₡',
    label: 'Costa Rican Colón',
    value: 'CRC',
  },
  CVE: {
    symbol: 'CV$',
    label: 'Cape Verdean Escudo',
    value: 'CVE',
  },
  CZK: {
    symbol: 'Kč',
    label: 'Czech Republic Koruna',
    value: 'CZK',
  },
  DJF: {
    symbol: 'Fdj',
    label: 'Djiboutian Franc',
    value: 'DJF',
  },
  DKK: {
    symbol: 'Dkr',
    label: 'Danish Krone',
    value: 'DKK',
  },
  DOP: {
    symbol: 'RD$',
    label: 'Dominican Peso',
    value: 'DOP',
  },
  DZD: {
    symbol: 'DA',
    label: 'Algerian Dinar',
    value: 'DZD',
  },
  EEK: {
    symbol: 'Ekr',
    label: 'Estonian Kroon',
    value: 'EEK',
  },
  EGP: {
    symbol: 'EGP',
    label: 'Egyptian Pound',
    value: 'EGP',
  },
  ERN: {
    symbol: 'Nfk',
    label: 'Eritrean Nakfa',
    value: 'ERN',
  },
  ETB: {
    symbol: 'Br',
    label: 'Ethiopian Birr',
    value: 'ETB',
  },
  GBP: {
    symbol: '£',
    label: 'British Pound Sterling',
    value: 'GBP',
  },
  GEL: {
    symbol: 'GEL',
    label: 'Georgian Lari',
    value: 'GEL',
  },
  GHS: {
    symbol: 'GH₵',
    label: 'Ghanaian Cedi',
    value: 'GHS',
  },
  GNF: {
    symbol: 'FG',
    label: 'Guinean Franc',
    value: 'GNF',
  },
  GTQ: {
    symbol: 'GTQ',
    label: 'Guatemalan Quetzal',
    value: 'GTQ',
  },
  HKD: {
    symbol: 'HK$',
    label: 'Hong Kong Dollar',
    value: 'HKD',
  },
  HNL: {
    symbol: 'HNL',
    label: 'Honduran Lempira',
    value: 'HNL',
  },
  HRK: {
    symbol: 'kn',
    label: 'Croatian Kuna',
    value: 'HRK',
  },
  HUF: {
    symbol: 'Ft',
    label: 'Hungarian Forint',
    value: 'HUF',
  },
  IDR: {
    symbol: 'Rp',
    label: 'Indonesian Rupiah',
    value: 'IDR',
  },
  ILS: {
    symbol: '₪',
    label: 'Israeli New Sheqel',
    value: 'ILS',
  },
  INR: {
    symbol: 'Rs',
    label: 'Indian Rupee',
    value: 'INR',
  },
  IQD: {
    symbol: 'IQD',
    label: 'Iraqi Dinar',
    value: 'IQD',
  },
  IRR: {
    symbol: 'IRR',
    label: 'Iranian Rial',
    value: 'IRR',
  },
  ISK: {
    symbol: 'Ikr',
    label: 'Icelandic Króna',
    value: 'ISK',
  },
  JMD: {
    symbol: 'J$',
    label: 'Jamaican Dollar',
    value: 'JMD',
  },
  JOD: {
    symbol: 'JD',
    label: 'Jordanian Dinar',
    value: 'JOD',
  },
  JPY: {
    symbol: '¥',
    label: 'Japanese Yen',
    value: 'JPY',
  },
  KES: {
    symbol: 'Ksh',
    label: 'Kenyan Shilling',
    value: 'KES',
  },
  KHR: {
    symbol: 'KHR',
    label: 'Cambodian Riel',
    value: 'KHR',
  },
  KMF: {
    symbol: 'CF',
    label: 'Comorian Franc',
    value: 'KMF',
  },
  KRW: {
    symbol: '₩',
    label: 'South Korean Won',
    value: 'KRW',
  },
  KWD: {
    symbol: 'KD',
    label: 'Kuwaiti Dinar',
    value: 'KWD',
  },
  KZT: {
    symbol: 'KZT',
    label: 'Kazakhstani Tenge',
    value: 'KZT',
  },
  LBP: {
    symbol: 'L.L.',
    label: 'Lebanese Pound',
    value: 'LBP',
  },
  LKR: {
    symbol: 'SLRs',
    label: 'Sri Lankan Rupee',
    value: 'LKR',
  },
  LTL: {
    symbol: 'Lt',
    label: 'Lithuanian Litas',
    value: 'LTL',
  },
  LVL: {
    symbol: 'Ls',
    label: 'Latvian Lats',
    value: 'LVL',
  },
  LYD: {
    symbol: 'LD',
    label: 'Libyan Dinar',
    value: 'LYD',
  },
  MAD: {
    symbol: 'MAD',
    label: 'Moroccan Dirham',
    value: 'MAD',
  },
  MDL: {
    symbol: 'MDL',
    label: 'Moldovan Leu',
    value: 'MDL',
  },
  MGA: {
    symbol: 'MGA',
    label: 'Malagasy Ariary',
    value: 'MGA',
  },
  MKD: {
    symbol: 'MKD',
    label: 'Macedonian Denar',
    value: 'MKD',
  },
  MMK: {
    symbol: 'MMK',
    label: 'Myanma Kyat',
    value: 'MMK',
  },
  MOP: {
    symbol: 'MOP$',
    label: 'Macanese Pataca',
    value: 'MOP',
  },
  MUR: {
    symbol: 'MURs',
    label: 'Mauritian Rupee',
    value: 'MUR',
  },
  MXN: {
    symbol: 'MX$',
    label: 'Mexican Peso',
    value: 'MXN',
  },
  MYR: {
    symbol: 'RM',
    label: 'Malaysian Ringgit',
    value: 'MYR',
  },
  MZN: {
    symbol: 'MTn',
    label: 'Mozambican Metical',
    value: 'MZN',
  },
  NAD: {
    symbol: 'N$',
    label: 'Namibian Dollar',
    value: 'NAD',
  },
  NGN: {
    symbol: '₦',
    label: 'Nigerian Naira',
    value: 'NGN',
  },
  NIO: {
    symbol: 'C$',
    label: 'Nicaraguan Córdoba',
    value: 'NIO',
  },
  NOK: {
    symbol: 'Nkr',
    label: 'Norwegian Krone',
    value: 'NOK',
  },
  NPR: {
    symbol: 'NPRs',
    label: 'Nepalese Rupee',
    value: 'NPR',
  },
  NZD: {
    symbol: 'NZ$',
    label: 'New Zealand Dollar',
    value: 'NZD',
  },
  OMR: {
    symbol: 'OMR',
    label: 'Omani Rial',
    value: 'OMR',
  },
  PAB: {
    symbol: 'B/.',
    label: 'Panamanian Balboa',
    value: 'PAB',
  },
  PEN: {
    symbol: 'S/.',
    label: 'Peruvian Nuevo Sol',
    value: 'PEN',
  },
  PHP: {
    symbol: '₱',
    label: 'Philippine Peso',
    value: 'PHP',
  },
  PKR: {
    symbol: 'PKRs',
    label: 'Pakistani Rupee',
    value: 'PKR',
  },
  PLN: {
    symbol: 'zł',
    label: 'Polish Zloty',
    value: 'PLN',
  },
  PYG: {
    symbol: '₲',
    label: 'Paraguayan Guarani',
    value: 'PYG',
  },
  QAR: {
    symbol: 'QR',
    label: 'Qatari Rial',
    value: 'QAR',
  },
  RON: {
    symbol: 'RON',
    label: 'Romanian Leu',
    value: 'RON',
  },
  RSD: {
    symbol: 'din.',
    label: 'Serbian Dinar',
    value: 'RSD',
  },
  RUB: {
    symbol: 'RUB',
    label: 'Russian Ruble',
    value: 'RUB',
  },
  RWF: {
    symbol: 'RWF',
    label: 'Rwandan Franc',
    value: 'RWF',
  },
  SAR: {
    symbol: 'SR',
    label: 'Saudi Riyal',
    value: 'SAR',
  },
  SDG: {
    symbol: 'SDG',
    label: 'Sudanese Pound',
    value: 'SDG',
  },
  SEK: {
    symbol: 'Skr',
    label: 'Swedish Krona',
    value: 'SEK',
  },
  SGD: {
    symbol: 'S$',
    label: 'Singapore Dollar',
    value: 'SGD',
  },
  SOS: {
    symbol: 'Ssh',
    label: 'Somali Shilling',
    value: 'SOS',
  },
  SYP: {
    symbol: 'SY£',
    label: 'Syrian Pound',
    value: 'SYP',
  },
  THB: {
    symbol: '฿',
    label: 'Thai Baht',
    value: 'THB',
  },
  TND: {
    symbol: 'DT',
    label: 'Tunisian Dinar',
    value: 'TND',
  },
  TOP: {
    symbol: 'T$',
    label: 'Tongan Paʻanga',
    value: 'TOP',
  },
  TRY: {
    symbol: 'TL',
    label: 'Turkish Lira',
    value: 'TRY',
  },
  TTD: {
    symbol: 'TT$',
    label: 'Trinidad and Tobago Dollar',
    value: 'TTD',
  },
  TWD: {
    symbol: 'NT$',
    label: 'New Taiwan Dollar',
    value: 'TWD',
  },
  TZS: {
    symbol: 'TSh',
    label: 'Tanzanian Shilling',
    value: 'TZS',
  },
  UAH: {
    symbol: '₴',
    label: 'Ukrainian Hryvnia',
    value: 'UAH',
  },
  UGX: {
    symbol: 'USh',
    label: 'Ugandan Shilling',
    value: 'UGX',
  },
  UYU: {
    symbol: '$U',
    label: 'Uruguayan Peso',
    value: 'UYU',
  },
  UZS: {
    symbol: 'UZS',
    label: 'Uzbekistan Som',
    value: 'UZS',
  },
  VEF: {
    symbol: 'Bs.F.',
    label: 'Venezuelan Bolívar',
    value: 'VEF',
  },
  VND: {
    symbol: '₫',
    label: 'Vietnamese Dong',
    value: 'VND',
  },
  XAF: {
    symbol: 'FCFA',
    label: 'CFA Franc BEAC',
    value: 'XAF',
  },
  XOF: {
    symbol: 'CFA',
    label: 'CFA Franc BCEAO',
    value: 'XOF',
  },
  YER: {
    symbol: 'YR',
    label: 'Yemeni Rial',
    value: 'YER',
  },
  ZAR: {
    symbol: 'R',
    label: 'South African Rand',
    value: 'ZAR',
  },
  ZMK: {
    symbol: 'ZK',
    label: 'Zambian Kwacha',
    value: 'ZMK',
  },
  ZWL: {
    symbol: 'ZWL$',
    label: 'Zimbabwean Dollar',
    value: 'ZWL',
  },
} as const;

export type Currency = keyof typeof CURRENCIES;
